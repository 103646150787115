import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import styles from './PageTitle.module.scss';

const PageTitle = ({ children: title, primary, narrow, left, className }) =>
  primary ? (
    <h1
      className={cn(styles.pageTitle, className, {
        [styles.pageTitleNarrow]: narrow,
        [styles.pageTitleLeft]: left
      })}
    >
      {title}
    </h1>
  ) : (
    <h2 className={cn(styles.pageTitle, className)}>{title}</h2>
  );

PageTitle.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  left: PropTypes.bool,
  narrow: PropTypes.bool,
  primary: PropTypes.bool
};

export default PageTitle;
