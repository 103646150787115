import React from 'react';
import cn from 'classnames';
import PropTypes from 'prop-types';

import styles from './PageContent.module.scss';

const PageContent = ({ children, className, ...props }) => (
  <div className={cn(className, styles.pageContent)} {...props}>
    {children}
  </div>
);

PageContent.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string
};

export default PageContent;
