import React from 'react';

import Layout from '../components/Layout';
import ContentContainer from 'components/ContentContainer';
import PageContent from 'components/PageContent';
import PageTitle from 'components/PageTitle';
import PageSubtitle from 'components/PageSubtitle';
import Button from 'components/Button';

const NotFoundPage = () => (
  <Layout>
    <ContentContainer style={{ marginTop: 50, height: 250 }}>
      <PageContent>
        <PageTitle>404</PageTitle>
        <PageSubtitle>Diese Seite konnte leider nicht gefunden werden</PageSubtitle>
        <div style={{ width: '100%', textAlign: 'center' }}>
          <Button
            title="Zur Startseite"
            link="/"
            type="primary"
            style={{ marginTop: 40 }}
          />
        </div>
      </PageContent>
    </ContentContainer>
  </Layout>
);

export default NotFoundPage;
