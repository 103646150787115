import cn from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

import styles from './PageSubtitle.module.scss';

const PageSubtitle = ({ children: title, left, className }) => (
  <h3 className={cn(styles.pageSubtitle, className, { [styles.pageSubtitleLeft]: left })}>
    {title}
  </h3>
);

PageSubtitle.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  left: PropTypes.bool
};

export default PageSubtitle;
